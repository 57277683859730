import pos from '@/services/poscrm'
import util from './util'

export default {
  updateOrder(data) {
    let order = {
      status: 'CANCEL',
      reason: 'cancel order by customer',
    }

    data.order = order

    pos({
      url: '/api/v1.1/' + data.uid + '/Receipt/updateorder',
      data: data,
      method: 'post',
    }).then((res) => {

    })
  },

  cancelOrder(data) {
    pos({
      url: '/api/v1.1/' + data.uid + '/Receipt/cancelorder',
      data: data,
      method: 'post',
    }).then((res) => {

    })
  },

  updateAmount(data, quantity) {
    let amount = 0
    let quantitySKU = quantity * data.ratio

    let price = 0.0
    if (data.price !== undefined) {
      price = data.price

      let optionPrice = 0
      for (let item in data.orderedOptions) {
        if (data.orderedOptions[item].price !== undefined) {
          optionPrice += data.orderedOptions[item].price
        }

      }

      amount = (data.price + optionPrice) * quantity
    } else {
      price = data.productSKU.SKUPrice
      amount = quantitySKU * data.productSKU.SKUPrice
    }

    return {
      price: price,
      amount: amount,
      quantitySKU: quantitySKU,
    }
  },

  updateAmountDelivery(data, quantity) {
    let newData = data
    let newQuantity = data.quantity + quantity
    let optionPrice = 0
    for (let item in data.orderedOptions) {
      if (data.orderedOptions[item].price !== undefined) {
        optionPrice += data.orderedOptions[item].price
      }
    }
    let newTotalAmount = (data.price + optionPrice) * newQuantity

    newData.totalAmount = newTotalAmount
    newData.quantity = newQuantity

    return newData
  },

  createReceiptItemsDelivery(SKUData, PLUData, quantity, note, orderedOptions, profile) {
    const quantitySKU = quantity * PLUData.SKURatio
    const id = util.createObjectId()
    let options = []

    let totalOption = 0.0
    let exceedUc = 0.0

    if (orderedOptions.length > 0) {
      options = this.createOrderOptionDefault(SKUData.id, orderedOptions)

      options.forEach((item) => {
        if (item.price !== undefined) {
          totalOption += item.price
        }
      })
    }
    if (PLUData.ProductPRU != undefined) {
      exceedUc = PLUData.ProductPRU.exceedUc
    } else {
      exceedUc = SKUData.SKUPrice
    }

    const totalAmount = (exceedUc + totalOption) * quantity

    let data = {
      profile: {
        objectId: profile.objectId,
        id: profile.id,
        name: profile.name,
      },
      shop: SKUData.shop,
      productPLU: {
        unit: {
          objectId: PLUData.unit.objectId,
          id: PLUData.unit.id,
          name: PLUData.unit.name
        },
        objectId: PLUData.objectId,
        enabled: true,
        name: PLUData.name,
        SKURatio: PLUData.SKURatio,
        indexColor: SKUData.indexColor,
        id: PLUData.id,
        PLUCode: PLUData.PLUCode,
      },
      productSKU: {
        objectId: SKUData.objectId,
        SKUName: SKUData.SKUName,
        SKUPrice: SKUData.SKUPrice,
        enabled: true,
        id: SKUData.id,
        indexColor: SKUData.indexColor,
        isOnScreen: true,
        serviceCharge: SKUData.serviceCharge,
        skuType: SKUData.skuType,
        vatType: SKUData.vatType,
        stdCost: SKUData.stdCost
      },
      totalAmount: totalAmount,
      price: exceedUc,
      remoteImagePath: SKUData.remoteImagePath,
      note: note,
      quantity: quantity,
      isSelected: true,
    }
    if (options.length > 0) {
      data['orderedOptions'] = options
    }
    return data

  },

  createReceiptItemsDefault(SKUData, PLUData, quantity, note, orderedOptions) {
    const quantitySKU = quantity * PLUData.SKURatio
    const id = util.createObjectId()
    let options = []

    let totalOption = 0.0
    let exceedUc = 0.0

    if (orderedOptions.length > 0) {
      options = this.createOrderOptionDefault(SKUData.id, orderedOptions)

      options.forEach((item) => {
        if (item.price !== undefined) {
          totalOption += item.price
        }
      })
    }
    if (PLUData.ProductPRU != undefined) {
      exceedUc = PLUData.ProductPRU.exceedUc
    } else {
      exceedUc = SKUData.SKUPrice
    }

    const totalAmount = (exceedUc + totalOption) * quantity
    //let netAmount = totalAmount - receiptItemToUpdate.discountAmount
    const name = localStorage.getItem('name') || ''


    let data = {
      shop: SKUData.shop,
      shopId: SKUData.shopId,
      uid: SKUData.uid,
      id: id,
      category: SKUData.category,
      categoryId: SKUData.categoryId,
      categoryName: SKUData.category.name,
      created_at: new Date(),
      created_by: SKUData.created_by,
      created_by_id: SKUData.created_by_id,
      indexColor: SKUData.indexColor,
      skuType: SKUData.skuType,
      stdCost: SKUData.stdCost,
      vatType: SKUData.vatType,
      updated_at: SKUData.updated_at,
      updated_by: SKUData.updated_by,
      updated_by_id: SKUData.updated_by_id,
      unit: SKUData.unit,
      remoteImagePath: SKUData.remoteImagePath,
      productPLU: {
        PLUCode: PLUData.PLUCode,
        SKURatio: PLUData.SKURatio,
        id: PLUData.id,
        name: PLUData.name,
        objectId: PLUData.objectId,
        unit: PLUData.unit,
      },
      pluCode: PLUData.PLUCode,
      pluName: PLUData.name,
      productPLUId: PLUData.id,
      productSKU: PLUData.productSKU,
      productSKUId: PLUData.productSKUId,
      ratio: PLUData.SKURatio,
      discountAmount: 0,
      discountBillPerQty: 0,
      quantity: quantity,
      quantitySKU: quantitySKU,
      netAmount: totalAmount,
      totalAmount: totalAmount,
      price: exceedUc,
      note: note,
      status: 'N',
      serviceCharge: SKUData.serviceCharge,
      ordered_by: name
    }
    if (options.length > 0) {
      data['orderedOptions'] = options
      return data
    } else {
      return data
    }
  },

  orderDefault(uid, shop, receiptItems, payment) {
    return {
      uid: uid,
      shopObjectId: shop.objectId,
      receipt: {
        order: {
          orderInputType: 'SMART-MENU',
        },
        uid: shop.uid,
        shop: {
          branchName: shop.branchName,
          id: shop.id,
          objectId: shop.objectId,
        },
        shopId: shop.id,
        receiptItems: receiptItems,
        objectId: '',
        created_at: '',
        id: '',
        lastUpdated: '',
        payReceiptAt: payment.payReceiptAt,
        payment: payment.payment,
        paymentId: payment.paymentId,
        pos: {
          id: '',
          objectId: '',
          posNumber: '',
        },
        posId: '',
        productNames: [],
        serverTimestamp: '',
        discountAmount: 0,
        discountItem: 0,
        grandTotal: 0,
        netAmount: 0,
        netAmountIncServiceCharge: 0,
        netAmountIncVAT: 0,
        netAmountNonVAT: 0,
        netAmountVATTotal: 0,
        netTotal: 0,
        netTotalIncVAT: 0,
        netTotalNonVAT: 0,
        quantityIncVAT: 0,
        quantityNonVAT: 0,
        queueIndex: 0,
        serviceCharge: 0,
        serviceChargePercent: 0,
        isVATIncluded: true,
      },
    }
  },

  orderDineIn(uid, shop, receiptItems, table, timestamp, pax) {
    const numCust = parseInt(pax)
    return {
      uid: uid,
      shopObjectId: shop.objectId,
      receipt: {
        numCust: parseInt(pax),
        QRTimestamp: timestamp,
        source: 'SELF_ORDER',
        receiptStatus: 'O',
        table: {
          id: table.id,
          objectId: table.objectId,
          name: table.name,
        },
        order: {
          orderInputType: 'SMART-MENU',
        },
        uid: shop.uid,
        shop: {
          branchName: shop.branchName,
          id: shop.id,
          objectId: shop.objectId,
        },
        shopId: shop.id,
        receiptItems: receiptItems,
        objectId: '',
        created_at: '',
        id: '',
        lastUpdated: '',
        pos: {
          id: '',
          objectId: '',
          posNumber: '',
        },
        posId: '',
        productNames: [],
        serverTimestamp: '',
        discountAmount: 0,
        discountItem: 0,
        grandTotal: 0,
        netAmount: 0,
        netAmountIncServiceCharge: 0,
        netAmountIncVAT: 0,
        netAmountNonVAT: 0,
        netAmountVATTotal: 0,
        netTotal: 0,
        netTotalIncVAT: 0,
        netTotalNonVAT: 0,
        quantityIncVAT: 0,
        quantityNonVAT: 0,
        queueIndex: 0,
        serviceCharge: 0,
        serviceChargePercent: 0,
        isVATIncluded: true,
      },
    }
  },

  createOrderOptionDefault(receiptItemId, data) {
    try {
      return data.map(item => {
        const objectId = util.createObjectId();
        const id = util.createObjectId();
        const optionItem = item.items[0];
        const optionGroup = item.OptionGroup;
        const itemId = optionItem.id
        const filterItemInGroupId = optionGroup.items.filter(item => item.optionItem.id === itemId);
        const itemInGroupId = filterItemInGroupId[0].id

        let resp = {
          optionItemName: optionItem.name,
          optionItem: {
            id: optionItem.id,
            objectId: optionItem.objectId,
            name: optionItem.name,
          },
          itemInGroupId: itemInGroupId,
          optionGroup: {
            id: optionGroup.id,
            objectId: optionGroup.objectId,
            name: optionGroup.name,
          },
          optionItemId: optionItem.id,
          activeOptionId: '',
          receiptItemId: receiptItemId,
          optionProductPLUs: [],
          objectId: objectId,
          quantity: 1,
          id: id,
        };


        if (optionItem.price !== undefined) {
          resp.price = optionItem.price
        }

        if (optionItem.cost !== undefined) {
          resp.cost = optionItem.cost
        }

        return resp;
      });
    } catch (error) {
      console.error("Error creating order option default:", error);
      return [];
    }
  },
}
